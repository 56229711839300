export const colors = {
  primary: '#002664',
  secondary: '#D7153A',
  warning: '#fbc02d',
  success: '#388e3c',
  error: '#d32f2f',
  white1: '#fafafa',
  grey1: 'rgba(0,0,0,0.695)',
  black1: '#111111',
  black2: '#333333',
};

export const fonts = {
  primary: 'var(--nsw-font-family)',
};

export const screens = {
  xs: '576px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1536px',
};

export const utils = {
  tableBorder: '1px solid rgba(0,0,0,0.15)',
};
