import { Button } from 'nsw-ds-react';
import { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';

import { Dialog, DialogTitle, DialogContent, DialogFooter } from '@components/NativeDialog';
import useAuth from '@helper/hooks/useAuth';
import {LOGOUT_URL} from '@helper/constants';

export default function NoRoleDialog({ isOpen, onClose }) {
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies();

  const closeDialog = () => {
    onClose(false)
    window.location.href=LOGOUT_URL;
  };

  return (
    <Dialog isOpen={isOpen} data-testid="idleNotificationDialog">
      <DialogTitle>No Access</DialogTitle>
      <DialogContent>
        <p className="tw-mt-2">
          You do not have the appropriate role and functionality will be limited.
        </p>
      </DialogContent>

      <DialogFooter>
        <div className="tw-flex tw-justify-end">
          <Button theme="dark-outline" className="tw-mt-0" onClick={closeDialog}>
            Close and continue
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
