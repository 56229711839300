import { create } from 'zustand';

export const useSnackbarStore = create((set) => ({
  isOpen: false,
  message: '',
  type: 'success',
  handleSuccess: (message) => set({ isOpen: true, message, type: 'success' }),
  handleError: (message) => set({ isOpen: true, message, type: 'error' }),
  handleThankYou: (message) => set({ isOpen: true, message, type: 'thanks' }),
  handleClose: () => set({ isOpen: false }),
}));
