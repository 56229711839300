import { intersection as _intersection } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import useAuth from '@helper/hooks/useAuth';

function PageRequireOu({ roles = [], redirectURL = '/not-authorised', healthEntity='HS', ...props }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const user = useAuth();

  useEffect(() => {
    if (user.isLoading) return;

    if (user.isLoggedIn) {
      const hasRoles = _intersection(user.Roles, roles);
      const hasOu = user.HealthEntity.includes(healthEntity)
      if (hasRoles && hasRoles.length > 0 && hasOu) {
        setIsAuthenticated(true);
        return;
      }
    }else{
        setIsAuthenticated(true);
        return;
    }

    setIsAuthenticated(false);
  }, [roles, user.Roles, user.isLoading, user.isLoggedIn, user.HealthEntity, healthEntity]);

  if (isAuthenticated === undefined || isAuthenticated === null) {
    return <></>;
  }

  if (isAuthenticated) {
    return props.children;
  } else {
    return (
      <Navigate
        to={{
          pathname: redirectURL,
        }}
      />
    );
  }
}

export default function RouteRequireOu({ roles = [], redirectURL = '/not-authorised', healthEntity = "HS"}) {
  return (
    <PageRequireOu roles={roles} redirectURL={redirectURL} healthEntity={healthEntity}>
      <Outlet />
    </PageRequireOu>
  );
}
