import styled from '@emotion/styled';
import { RiLoader5Line as LoadingIcon } from 'react-icons/ri';

const CircularProgress = ({ size = 'sm', fill = 'primary', className = '' }) => {
  return (
    <IconContainer className={`d-flex align-items-center fill-${fill} size-${size} ${className}`}>
      <LoadingIcon className="spin-animation" data-testid="loading-icon" />
    </IconContainer>
  );
};

const IconContainer = styled.div`
  width: fit-content;
  margin: 0 auto;

  svg {
    --size: 24px;
    width: var(--size);
    height: var(--size);
  }

  &.size-sm {
    svg {
      --size: 24px;
    }
  }
  &.size-lg {
    svg {
      --size: 50px;
    }
  }
  &.size-xl {
    svg {
      --size: 60px;
    }
  }

  &.fill-primary {
    svg {
      fill: ${(p) =>
        // @ts-ignore
        p?.theme?.colors?.primary};
    }
  }
  &.fill-secondary {
    svg {
      fill: ${(p) =>
        // @ts-ignore
        p?.theme?.colors?.secondary};
    }
  }

  .spin-animation {
    animation: spin 600ms ease-in-out infinite forwards;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export default CircularProgress;
