import clsx from 'clsx';
import { forwardRef } from 'react';

type Props = {
  className?: string;
  [key: string]: any;
};

const NSWInputField = forwardRef<HTMLInputElement, Props>((props, forwardedRef) => {
  const { className, ...others } = props;

  return (
    <input
      ref={forwardedRef}
      className={clsx({
        'nsw-form__input tw-py-3 tw-px-4 tw-rounded-r-[var(--nsw-border-radius)]': 1,
        'tw-bg-transparent tw-p-0 tw-border-0 tw-w-full tw-outline-0 tw-grow ': 1,
        [className]: className,
      })}
      {...others}
    />
  );
});

export default NSWInputField;
