import { Link } from 'react-router-dom';
import NswGovLogo from './NswGovLogo';

export default function Logo() {
  return (
    <>
      <div className="nsw-header__waratah">
        <Link className="nsw-header__waratah-link" to="/">
          <NswGovLogo />
          <span className="sr-only">NSW Government</span>
        </Link>
      </div>
      <div className="nsw-header__name">
        <div className="nsw-header__title">NSW Health</div>
      </div>
    </>
  );
}
