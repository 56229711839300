import styled from '@emotion/styled';

export const StyledLoadingContainer = styled.div`
  height: 100%;
`;

export const StyledExpandableTable = styled.table`
  tbody .tr-expanded {
    border: 1px solid black;
    border-bottom: 0;
  }

  tbody .tr-expanded-details {
    border: 1px solid black;
    border-top: 0;
  }
`;

export const HeaderBtn = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0;
  border-radius: var(--nsw-border-radius);
  padding: 0.5rem;
  background-color: transparent;

  &.sort-btn-selected {
    background-color: var(--nsw-status-info-bg);
  }

  &:hover {
    background-color: var(--nsw-text-hover);
  }
  &:focus {
    background-color: var(--nsw-focus);
  }
`;

export const HeaderBtnText = styled.span`
  padding: 0.5rem 0.2rem;
  font-size: 0.9rem;
  font-weight: var(--nsw-font-bold);
  white-space: nowrap;
`;

export const StyledTableTh = styled.th`
  min-height: 16px;
`;

export const SortedTableTh = styled(StyledTableTh)`
  button .sort-btn-selected {
    background-color: var(--nsw-status-info-bg);
  }

  button .sort-btn {
    background-color: transparent;
  }
`;

export const StyledTableRow = styled.tr`
  &.border-top {
    border-top: 2px solid;
    border-color: black;
  }
`;

export const StyledItemActionButton = styled.button`
  display: inline-flex;
  margin: 0;
  padding: 0.5rem;
  background-color: transparent;
  &:hover {
    background-color: var(--nsw-text-hover);
  }
  &:focus {
    background-color: var(--nsw-focus);
  }
`;

export const StyledActionTd = styled.td`
  vertical-align: middle !important;
`;

export const TableExpandedDetailsContainer = styled.div`
  max-width: calc(100vw - 3rem);
  position: sticky;
  left: 0.5rem;
  background-color: white;
  overflow: auto;
  margin-bottom: 2px;
  padding: 0.5rem;
`;
