import { useCallback } from 'react';
import { colors } from '@theme/constants';
import { useLocation } from 'react-router-dom';
import EnableSectionLinks from './components/enable';
import useFooterNavLinks from '@helper/hooks/useFooterNavLinks';

export default function Footer() {
  const { pathname } = useLocation();

  const navLinks = useFooterNavLinks();

  const SectionLinks = useCallback(() => {
    if (pathname.startsWith('/enablensw')) {
      return EnableSectionLinks();
    }

    return null;
  }, [pathname]);

  const handleOnLinkClick = useCallback((x) => {
    if (x && x.sideEffect) {
      x.sideEffect();
    }
  }, []);

  return (
    <footer className="nsw-footer mt-auto">
      {SectionLinks() && (
        <div className="nsw-footer__upper">
          <div className="nsw-container section-links">
            <SectionLinks />
          </div>
        </div>
      )}
      <div
        className="nsw-footer__lower"
        style={{
          backgroundColor: colors.primary,
        }}
      >
        <div className="nsw-container d-flex flex-column flex-md-row">
          <ul className="nsw-footer-links flex-grow-1">
            <li className="nsw-footer-links__item">
              <a href="https://www.nsw.gov.au" rel="noreferrer" className="nsw-footer-links__link">
                NSW Government
              </a>
            </li>
            <li className="nsw-footer-links__item">
              <a
                href="https://www.health.nsw.gov.au"
                rel="noreferrer"
                className="nsw-footer-links__link"
              >
                NSW Health
              </a>
            </li>
          </ul>
          <ul
            className="nsw-footer-links"
            style={{
              listStyle: 'none',
            }}
          >
            {navLinks.map((x) => (
              <li className="nsw-footer-links__item" key={x.name}>
                <a
                  href={x.link}
                  rel="noreferrer"
                  className="nsw-footer-links__link"
                  onClick={() => handleOnLinkClick(x)}
                >
                  {x.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}
