import clsx from 'clsx';
import { BaseSyntheticEvent } from 'react';

function getThemeClass(theme) {
  switch (theme) {
    case 'dark':
      return 'nsw-button--dark';
    case 'dark-outline':
      return 'nsw-button--dark-outline';
    case 'light':
      return 'nsw-button--light';
    case 'light-outline':
      return 'nsw-button--light-outline';
    case 'white':
      return 'nsw-button--white';
    case 'danger':
      return 'nsw-button--danger';
    default:
      return 'nsw-button--dark';
  }
}

type ThemeOptions = 'dark' | 'dark-outline' | 'light' | 'light-outline' | 'white' | 'danger';

interface ButtonProps {
  theme?: ThemeOptions;
  icon?: React.ReactNode;
  beforeIcon?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  onClick?: (e: BaseSyntheticEvent) => void;
  [key: string]: any;
}

interface ButtonLinkProps extends ButtonProps {
  href?: string;
}

export default function Button({
  theme = 'dark',
  icon,
  beforeIcon,
  children,
  className,
  ...others
}: ButtonProps) {
  return (
    <button
      type="button"
      className={clsx({
        'nsw-button': 1,
        [getThemeClass(theme)]: 1,
        [className]: className,
      })}
      {...others}
    >
      {beforeIcon}
      <span>{children}</span>
      {icon}
    </button>
  );
}

export function ButtonLink({
  href,
  theme = 'dark',
  icon,
  beforeIcon,
  children,
  className = '',
  ...others
}: ButtonLinkProps) {
  return (
    <a
      href={href}
      className={clsx({
        'nsw-button': 1,
        [getThemeClass(theme)]: 1,
        [className]: className,
      })}
      {...others}
    >
      {beforeIcon}
      <span>{children}</span>
      {icon}
    </a>
  );
}
