export default function NonProdWarning() {
  return (
    <div
      className="nsw-sitewide-message js-sitewide-message nsw-sitewide-message--critical"
      role="alert"
    >
      <div className="nsw-sitewide-message__wrapper">
        <div className="nsw-sitewide-message__content">
          <h2 className="nsw-sitewide-message__title">TEST SITE ONLY</h2>
          <p>This is a non-production environment for testing purposes only.</p>
          <p>
            To make a payment, visit:{' '}
            <a
              className="tw-text-off-white hover:tw-text-blue1"
              href="https://paynswhealth.health.nsw.gov.au/"
            >
              https://paynswhealth.health.nsw.gov.au/
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
