import { Outlet } from 'react-router-dom';

import PageRequireAuth from './PageRequireAuth';

export default function RouteRequireAuth({ roles = [], redirectURL = '/not-authorised' }) {
  return (
    <PageRequireAuth roles={roles} redirectURL={redirectURL}>
      <Outlet />
    </PageRequireAuth>
  );
}
