import jsonwebtoken from 'jsonwebtoken';

import { paymentFailedFallbackMessage } from '@helper/constants';

import { useActiveTransactionStore } from '@store/generic-payment';

import { colors } from '@theme/constants';

import DebugConsole from './console';

const errorMessageFormatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });

export const microformStyles = {
  input: {
    'font-size': '14px',
    'font-family': `'Montserrat', sans-serif`,
    color: '#555',
  },
  ':focus': { color: '#000' },
  ':disabled': { cursor: 'not-allowed' },
  valid: { color: '#111111' },
  invalid: { color: colors.error },
};

export const getTransientTokenFromJWT = (transientTokenJWT: string): string => {
  const decoded = jsonwebtoken.decode(transientTokenJWT);
  const transientToken = decoded.jti;

  return transientToken;
};

export const getMaskedValueCCFromJWT = (transientTokenJWT) => {
  const decoded = jsonwebtoken.decode(transientTokenJWT);
  const number_maskedvalue = decoded.content.paymentInformation.card.number.maskedValue;

  return number_maskedvalue;
};

export const getInvoiceReferenceCode = () => {
  const activeTx = useActiveTransactionStore.getState().activeTx;
  const row = activeTx.formData;
  const code = row.invoiceNumber || 'Donation';

  return code;
};

export const getMicroformErrorMessage = (error) => {
  DebugConsole.error(error);

  let errorMessage = error.message ?? '';
  const messageLocation = {};

  if (!error) return { errorMessage, messageLocation };

  if (errorMessage.includes('One or more fields have a validation error.')) {
    const detailsMessage = error.details.map((detail) => getCardValidationError(detail));
    error.details.forEach((detail) => {
      messageLocation[detail.location] = getCardValidationError(detail);
    });

    errorMessage = errorMessageFormatter.format(detailsMessage);
  } else if (error.details && error.details.length) {
    errorMessage = `${error.details.map((item) => item.message).join(',')}`;
  }

  return { errorMessage, messageLocation };
};

function getCardValidationError(detail) {
  if (detail.location === 'number') return 'Invalid card number';
  if (detail.location === 'securityCode') return 'Invalid security code';
  return 'Invalid payment details';
}

const commonPayerAuthErrorMessages = {
  authenticationRequired: 'Note: Additional authentication is required by the issuing bank',
  encounteredPayerAuthProblem:
    'Encountered a Payer Authentication problem. Payer could not be authenticated',
};

export const getPayerAuthEnrolmentErrorMessage = (enrollData) => {
  let errorMessage =
    enrollData.consumerAuthenticationInformation?.cardholderMessage ||
    enrollData?.errorInformation.message ||
    paymentFailedFallbackMessage;

  if (errorMessage.includes(commonPayerAuthErrorMessages.authenticationRequired)) {
    errorMessage = paymentFailedFallbackMessage;
  } else if (errorMessage.includes(commonPayerAuthErrorMessages.encounteredPayerAuthProblem)) {
    errorMessage = paymentFailedFallbackMessage;
  }

  return errorMessage;
};

export const getValidatePayerAuthErrorMessage = (validateData) => {
  let errorMessage =
    validateData?.errorInformation?.message ||
    validateData.consumerAuthenticationInformation?.authenticationStatusMsg ||
    paymentFailedFallbackMessage;

  if (errorMessage.includes(commonPayerAuthErrorMessages.encounteredPayerAuthProblem)) {
    errorMessage = paymentFailedFallbackMessage;
  }

  return errorMessage;
};

export const getExpiryErrorMessages = (expiryMonth, expiryYear) => {
  let messages = [];

  if (expiryMonth) expiryMonth = expiryMonth.trim();
  if (expiryYear) expiryYear = expiryYear.trim();

  const invalidMessage = 'Invalid date format. Date should be in MM/YY';

  if (!expiryMonth || !expiryYear) return invalidMessage;
  if (expiryMonth.length > 2 || expiryYear.length !== 2) return invalidMessage;

  if (parseInt(expiryMonth) < 1 || parseInt(expiryMonth) > 12) return invalidMessage;

  const currentYearYY = parseInt(new Date().toLocaleDateString('en', { year: '2-digit' }));
  if (parseInt(expiryYear) < currentYearYY) messages.push('Invalid expiry year');

  if (parseInt(expiryYear) === currentYearYY) {
    const currentYearMM = parseInt(new Date().toLocaleDateString('en', { month: '2-digit' }));
    if (parseInt(expiryMonth) < currentYearMM) messages.push('Invalid expiry month');
  }

  return errorMessageFormatter.format(messages);
};
