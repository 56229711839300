import { useEffect, useState } from 'react';

import Modal from '@components/Modal';
import CircularProgress from '@components/CircularProgress';
import { DialogTransparent } from '@components/NativeDialog';

import { useAuthStore } from '@store/auth';

export const ComponentLoading = () => {
  return <CircularProgress fill="primary" />;
};

export const AuthLoadingIndicator = () => {
  const isInitializing = useAuthStore((s) => s.isInitializing);

  return (
    <Modal isOpen={isInitializing} data-testid="authLoadingModal">
      <CircularProgress size="lg" fill="secondary" />
    </Modal>
  );
};

export function AuthLoadingContainer(props) {
  const isInitializing = useAuthStore((s) => s.isInitializing);

  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    setIsOpen(isInitializing);
  }, [isInitializing, setIsOpen]);

  return (
    <>
      <DialogTransparent
        isOpen={isOpen}
        data-testid="authLoadingModal"
        className="tw-overflow-visible"
      >
        <CircularProgress size="lg" fill="secondary" />
      </DialogTransparent>
      {!isInitializing && props.children}
    </>
  );
}
