import { getExpiryErrorMessages } from '@helper/functions/flex';

export const invoiceTableTitles = [
  'Invoice Number',
  'Invoice Date',
  'Health Entity',
  'Amount',
  'Amount Remaining',
];
export const invoiceTableFieldNames = {
  invoiceNumber: 'invoiceNumber',
  invoiceDate: 'invoiceDate',
  amount: 'amount',
  healthEntity: 'healthEntity',
  amountDueRemaining: 'amountDueRemaining',
};
export const invoiceRowReferenceKey = invoiceTableFieldNames.invoiceNumber;

export const defaultCardDetails = {
  cardType: '',
  expiry: '',
  amount: '',
};

export const defaultDonationAmount = 50;

export const payerAuthStatus = {
  pending: 'PENDING_AUTHENTICATION',
  success: 'AUTHENTICATION_SUCCESSFUL',
  failed: 'AUTHENTICATION_FAILED',
};

export const paymentFailedFallbackMessage =
  'Payment unsuccessful. Please try another card or contact your financial institution.';

export const commonValidations = {
  fullName: {
    maxLength: 100,
    pattern: {
      value: /^[^\d,]*$/,
      message: 'You have not entered a valid name',
    },
  },

  firstLastName: {
    maxLength: 49,
    pattern: {
      value: /^[^\d,]*$/,
      message: 'You have not entered a valid name',
    },
  },

  expiry: {
    // required: true,
    validate: (value) => {
      const [expiryMonth, expiryYear] = value.split('/');
      const expiryErrors = getExpiryErrorMessages(expiryMonth, expiryYear);

      if (expiryErrors) return expiryErrors;

      return true;
    },
  },

  /**
   * A very basic email pattern. Allows any combinations of `*@*.*` (where `*` can be anything but `@`), which is actually overly permissive and would allow many malformed email addresses.
   * However, the UI cannot actually check for email address validity because the email provider always has the final say. Although there are standards for email addresses (RFC822, RFC2822), major email providers often restrict the characters to a much narrower subset of what are technically allowed by the standards. So, proper backend error handling is more important than a "strict" pattern validation rule.
   */
  email: {
    pattern: {
      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      message: 'You have not entered a valid email address',
    },
  },

  stafflink: {
    pattern: /[0-9]{8}/,
  },

  // Allowed numbers: 0x xxxx xxxx, 1x xxxx, 1x xxxx xxxx
  phoneNumber: {
    pattern: {
      value: /^(?:(0[1-9]\d{8})|(1\d{5})|(1\d{9}))$/,
      message: 'You have not entered a valid phone number',
    },
  },

  // Allowed numbers: 0x xxxx xxxx, 13 xxxx xxxx, 18 xxxx xxxx, 13 xxxx
  phoneNumberStrict: {
    pattern: {
      value: /^(0[1-9]\d{8})|(1[38]\d{8})|(13\d{4})$/,
      message: 'You have not entered a valid phone number',
    },
  },
};

export const LOGOUT_URL =
  process.env.REACT_APP_SSO_DOMAIN_NAME +
  '/logout?client_id=' +
  process.env.REACT_APP_SSO_CLIENT_ID +
  '&logout_uri=' +
  process.env.REACT_APP_INVOICE_API_BASE_URL +
  '/logout';

export const LOGIN_URL = process.env.REACT_APP_INVOICE_API_BASE_URL + '/Employee/Login';

export const LOGOUT_RELOGIN_URL = process.env.REACT_APP_SSO_DOMAIN_NAME +
'/logout?client_id=' +
process.env.REACT_APP_SSO_CLIENT_ID +
'&logout_uri=' +
process.env.REACT_APP_INVOICE_API_BASE_URL +
'/relogin';

export const ADMIN_URL = process.env.REACT_APP_INVOICE_API_BASE_URL + '/admin';

