import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const defaultLinks = [];

export default function useNavLinks(props) {
  const { pathname } = useLocation();

  const [links, setLinks] = useState(defaultLinks);

  const addLinks = useCallback((newLinks) => {
    setLinks((items) => defaultLinks.concat(newLinks));
  }, []);

  useEffect(() => {
    switch (pathname) {
      case '/':
        addLinks([
          {
            name: 'Help',
            link: '/faqs',
          },
        ]);
        break;

      case '/invoice':
        addLinks([
          {
            name: 'Help',
            link: '/invoice/faqs',
          },
          {
            name: 'Contact Us',
            link: '/invoice/contact-us',
          },
        ]);
        break;

      case '/contact-us':
        addLinks([
          {
            name: 'Help',
            link: '/invoice/faqs',
          },
          {
            name: 'Contact Us',
            link: '/invoice/contact-us',
          },
        ]);
        break;

      case '/faqs':
        addLinks([
          {
            name: 'Help',
            link: '/faqs',
          },
        ]);
        break;

      case '/invoice/faqs':
        addLinks([
          {
            name: 'Help',
            link: '/invoice/faqs',
          },
          {
            name: 'Contact Us',
            link: '/invoice/contact-us',
          },
        ]);
        break;

      case '/donations/faqs':
        addLinks([
          {
            name: 'Help',
            link: '/donations/faqs',
          },
          {
            name: 'Contact Us',
            link: '/donations/contact-us',
          },
        ]);
        break;

      case '/donations/contact-us':
        addLinks([
          {
            name: 'Help',
            link: '/donations/faqs',
          },
          {
            name: 'Contact Us',
            link: '/donations/contact-us',
          },
        ]);
        break;

      case '/donations':
        addLinks([
          {
            name: 'Help',
            link: '/donations/faqs',
          },
          {
            name: 'Contact Us',
            link: '/donations/contact-us',
          },
        ]);
        break;

      case '/enablensw/faqs':
        addLinks([
          {
            name: 'Help',
            link: '/enablensw/faqs',
          },
          {
            name: 'Contact Us',
            link: 'https://www.enable.health.nsw.gov.au/about/contact',
          },
        ]);
        break;

      case '/enablensw':
        addLinks([
          {
            name: 'Help',
            link: '/enablensw/faqs',
          },
          {
            name: 'Contact Us',
            link: 'https://www.enable.health.nsw.gov.au/about/contact',
          },
        ]);
        break;

      case '/enablensw-auth':
        addLinks([
          {
            name: 'Help',
            link: '/faqs',
          },
        ]);
        break;

      case '/other-payments':
        addLinks([
          {
            name: 'Help',
            link: '/other-payments/faqs',
          },
          {
            name: 'Contact Us',
            link: '/other-payments/contact-us',
          },
        ]);
        break;

      case '/other-payments/faqs':
        addLinks([
          {
            name: 'Help',
            link: '/other-payments/faqs',
          },
          {
            name: 'Contact Us',
            link: '/other-payments/contact-us',
          },
        ]);
        break;

      case '/other-payments/contact-us':
        addLinks([
          {
            name: 'Help',
            link: '/other-payments/faqs',
          },
          {
            name: 'Contact Us',
            link: '/other-payments/contact-us',
          },
        ]);
        break;

      case '/admin/medical-records':
        addLinks([
          {
            name: 'Help',
            link: '/faqs',
          },
        ]);
        break;

      default:
        addLinks([
          {
            name: 'Help',
            link: '/faqs',
          },
          {
            name: 'Contact Us',
            link: '/invoice/contact-us',
          },
        ]);
        break;
    }
  }, [addLinks, pathname]);

  return links;
}
