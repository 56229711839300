import ReactDOM from 'react-dom';

import DebugConsole from '@helper/functions/console';

import App from './App';

if (window && process.env.NODE_ENV === 'development') {
  console.warn(`Importing DEV env... Final deployment runtime env may be different.`);

  window._APP_ENV = {};

  Object.keys(process.env).forEach((key) => {
    if (key.startsWith('REACT_APP_')) {
      window._APP_ENV[key] = process.env[key];
    }
  });
}

DebugConsole.debug(`App env:`, window._APP_ENV);

ReactDOM.render(<App />, document.getElementById('root'));
