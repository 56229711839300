import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

import { LOGIN_URL, LOGOUT_URL } from '@helper/constants';

import useAuth from './useAuth';

export default function useFooterNavLinks(pageFooterLinks) {
  const { pathname } = useLocation();
  const user = useAuth();

  const [links, setLinks] = useState([]);
  const [, setCookie] = useCookies(['UserName', 'UserFullName', 'lastLoginAttempt']);

  const [defaultLinks, setDefaultLinks] = useState([]);

  const setLoginTime = useCallback(() => {
    setCookie('lastLoginAttempt', Date.now());
  }, [setCookie]);

  const handleLoginClick = useCallback(() => {
    localStorage.removeItem('lastRecaptchaTime')
    fetch(LOGIN_URL + `?redirectTo=${pathname}`, {
      method: 'GET'
    })
    .then(response => response.json())
    .then(data => {
      console.log("SUCCESS")
      console.log(data);
      localStorage.setItem('accessToken', data.accessToken);
    });
  }, [pathname]);

  useEffect(() => {
    if (!user.isLoggedIn) {
      setDefaultLinks(() => [
        {
          name: 'Employee Login',
          link: LOGIN_URL + `?redirectTo=${pathname}`,
          sideEffect: () => {
            setLoginTime();
            handleLoginClick();
          },
        },
      ]);
    } else {
      setDefaultLinks(() => [
        {
          name: 'Logout',
          link: LOGOUT_URL,
        },
      ]);
    }
  }, [pathname, setLoginTime, user.isLoggedIn]);

  useEffect(() => {
    if (!pageFooterLinks) {
      setLinks(() => {
        return [...defaultLinks];
      });

      return;
    }

    setLinks(() => {
      return [...pageFooterLinks, ...defaultLinks];
    });
  }, [defaultLinks, pageFooterLinks, pathname]);

  return links;
}
