import clsx from 'clsx';
import { MdOutlineSearch } from 'react-icons/md';

export default function NSWInputActionButton({
  icon = <MdOutlineSearch />,
  className = '',
  ...others
}) {
  return (
    <button
      className={clsx({
        'nsw-button tw-bg-transparent tw-m-0 tw-min-w-[42px] tw-pl-2 tw-pr-3': 1,
        'tw-py-[0.75rem] tw-rounded-l-[var(--nsw-border-radius)]': 1,
        '[&_svg]:tw-text-xl': 1,
        [className]: className,
      })}
      aria-hidden
      {...others}
    >
      {icon}
    </button>
  );
}
