import clsx from 'clsx';
import { forwardRef, useCallback, useEffect, useRef } from 'react';

/**
 * @prop `allowEscape` false - If the dialog is shown with the `showModal` method, should it be allowed to be closed by pressing the "Escape" key.
 */
type DialogProps = {
  className?: string;
  children: any;
  allowEscape?: boolean;
  isOpen?: boolean;
  fitWidth?: boolean;
  [key: string]: any;
};

/**
 * Use browsers' native implementation of dialog modal.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/HTMLDialogElement
 * @method `showModal()` Show dialog as modal
 * @method `show()` Show dialog as non-modal
 * @method `close()` Close dialog
 */
export const Dialog = forwardRef<HTMLDialogElement, DialogProps>((props, forwardedRef) => {
  const {
    className = '',
    isOpen,
    allowEscape = false,
    fitWidth = false,
    children,
    ...others
  } = props;

  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      if (dialogRef.current?.open) return;
      dialogRef.current?.showModal();
    } else {
      if (!dialogRef.current?.open) return;
      dialogRef.current?.close();
    }
  }, [isOpen]);

  const handleEscape = useCallback(
    (e) => {
      if (allowEscape) return;
      e.preventDefault();
    },
    [allowEscape],
  );

  useEffect(() => {
    dialogRef.current?.addEventListener('cancel', handleEscape);

    return () => {
      dialogRef.current?.removeEventListener('cancel', handleEscape);
    };
  }, [handleEscape]);

  return (
    <dialog
      {...others}
      className={clsx({
        'tw-border-0 tw-outline-0 md:tw-drop-shadow tw-shadow-none tw-rounded-nsw tw-p-0 tw-min-w-[300px] tw-max-w-screen-md': 1,
        'tw-fixed tw-max-h-[90vh]': 1,
        'tw-w-full': !fitWidth,
        'tw-w-fit': fitWidth,
        [className]: className,
      })}
      ref={(node) => {
        if (dialogRef) {
          dialogRef.current = node;
        }

        if (typeof forwardedRef === 'function') {
          forwardedRef(node);
        } else if (forwardedRef) {
          forwardedRef.current = node;
        }
      }}
    >
      {isOpen && children}
    </dialog>
  );
});

export const DialogTransparent = forwardRef<HTMLDialogElement, DialogProps>((props, ref) => {
  const { className, ...others } = props;

  return (
    <Dialog
      {...others}
      className={clsx({
        'tw-bg-transparent': 1,
        [className]: className,
      })}
      ref={ref}
    />
  );
});

export function DialogTitle(props) {
  return (
    <div className="tw-font-bold tw-text-[1.2rem] tw-px-6 lg:tw-px-8 tw-pt-6 tw-pb-4">
      {props.children}
    </div>
  );
}

export function DialogContent(props) {
  return (
    <div className="tw-border-solid tw-border-0 tw-border-t tw-border-grey3 tw-px-6 lg:tw-px-8 tw-py-6">
      {props.children}
    </div>
  );
}

export function DialogFooter(props) {
  return (
    <div className="tw-border-solid tw-border-0 tw-border-t tw-border-grey3 tw-px-6 lg:tw-px-8 tw-pb-6 tw-pt-4">
      {props.children}
    </div>
  );
}

export default Dialog;
