import { useEffect, useRef, useState } from 'react';

import { useOutageStore } from '@src/store/outage';

import DebugConsole from '@helper/functions/console';
import { getCurrentOutageData } from '@helper/service/outage';

import useServerData from './useServerData';

const MS_10_MIN = 10 * 60 * 1000;

export default function useOutageData() {
  const abortControl = useRef<AbortController>(null);

  if (!abortControl.current) {
    abortControl.current = new AbortController();
  }

  useEffect(() => () => abortControl.current.abort(), []);

  const [hasOutage, setHasOutage] = useState(false);
  const [alertType, setAlertType] = useState('');
  const lastUpdate = useRef(Date.now());

  const serverData = useServerData();

  const store = useOutageStore((s) => s);
  const setOutageData = store.setOutageData;
  const setAlertOpen = store.setAlertOpen;
  const setAlertClosed = store.setAlertClosed;
  const outageData = store.outageRecord;
  const isAlertOpen = store.isAlertOpen;

  // Set initial outage data from server data
  useEffect(() => {
    const data = serverData.outageData;

    lastUpdate.current = Date.now();

    if (!data) {
      setHasOutage(false);
      return;
    }

    setOutageData(data);
   
    if (data.outageStatus === 'CURRENT') {
      setAlertOpen();
      setHasOutage(true);
      setAlertType('dark')
    }
    if (data.outageStatus === 'UPCOMING') {
      setAlertOpen();
      setHasOutage(true);
      setAlertType('light')
    }
  }, [serverData.outageData, setAlertOpen, setOutageData]);

  // Fetch new outage data periodically
  useEffect(() => {
    const getNewData = async () => {
      // Prevent repeated triggers
      if (Date.now() - lastUpdate.current < MS_10_MIN / 2) {
        return;
      }

      try {
        const newData = await getCurrentOutageData();

        if (abortControl.current.signal.aborted) return;

        lastUpdate.current = Date.now();
        DebugConsole.debug(`Refreshing outage data:`, newData);

        if (!newData) {
          return;
        }

        if (newData.outageStatus === 'NONE' && outageData?.outageStatus === 'NONE') {
          DebugConsole.debug(`No outage`);
          return;
        } else if (newData.uuid === outageData?.uuid) {
          DebugConsole.debug(`No outage update`);
          return;
        }

        DebugConsole.debug(`Updated outage data`);
        setOutageData(newData);
        setAlertOpen();
      } catch (err) {
        DebugConsole.error(err);
      }
    };

    // Run fetch function on a set interval
    const intervalId = setInterval(getNewData, MS_10_MIN);

    return () => {
      clearInterval(intervalId);
    };
  }, [outageData?.outageStatus, outageData?.uuid, setAlertOpen, setOutageData]);

  return { hasOutage, outageData, isAlertOpen, alertType, setAlertOpen, setAlertClosed };
}
