import { useEffect, useState } from 'react';

import DebugConsole from '@helper/functions/console';

/**
 * Decode server-side data embedded in the HTML <head>
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Base64#the_unicode_problem
 * @param {string} base64EncodedData
 * @returns {Object}
 */
function decodeData(base64EncodedData) {
  const binString = atob(base64EncodedData);
  const dataUIntArray = Uint8Array.from(binString, (m) => m.codePointAt(0));
  const decodedString = new TextDecoder().decode(dataUIntArray);

  const data = JSON.parse(decodedString);
  return data;
}

const localData = {
  // outageData: {
  //   lastModifiedDateTime: '2023-12-15T00:59:00Z',
  //   isDowntimeRequired: false,
  //   outageTitle: 'planned maintenance',
  //   createDateTime: '2023-12-15T00:59:00Z',
  //   createdBy: '60198113',
  //   outageType: 'planned-site',
  //   changeRequest: 'CHG1234567',
  //   expectedEndDateTime: '2023-12-15T19:00:43Z',
  //   outageDetails: 'test message',
  //   uuid: '61dae337-623d-45de-ab1e-9666105a1411',
  //   lastModifiedBy: '60198113',
  //   expectedStartDateTime: '2023-12-15T17:00:43Z',
  //   outageStatus: 'UPCOMING',
  // },
};

export default function useServerData() {
  const [serverData, setServerData] = useState<any>({});

  useEffect(() => {
    if (window && process.env.NODE_ENV === 'development') {
      // Local dev server data

      setServerData(localData);
      DebugConsole.warn('Loading local dev server data...');
      DebugConsole.debug(localData);
    } else {
      const documentData = decodeURIComponent(
        document.getElementById('server-data').dataset.serverData,
      );

      if (documentData && documentData !== '___SERVER_DATA___') {
        const data = decodeData(documentData);
        setServerData(data);
        DebugConsole.debug(data);
      }
    }
  }, []);

  return serverData;
}
