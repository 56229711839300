import { Button } from 'nsw-ds-react';
import { useState, useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useCookies } from 'react-cookie';

import { Dialog, DialogTitle, DialogContent, DialogFooter } from '@components/NativeDialog';
import useAuth from '@helper/hooks/useAuth';
import {LOGOUT_URL} from '@helper/constants'

const MILLIS_30_MIN = 1000 * 60 * 30;

export default function IdleNotificationDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const user = useAuth();

  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie] = useCookies();

  useEffect(() => {
    //if (user.isLoading) return;

    //const lastLogin = cookies.lastLoginAttempt;

    // if (user.isLoggedIn) {
    //   if (!lastLogin) {
    //     console.log("NOT last login")
    //     user.logout();
    //   } else if (lastLogin && Date.now() - lastLogin > 60 * 60 * 1000) {
    //     console.log("ITS BEEN 1 hour")
    //     user.logout();
    //   }
    // }
  }, [cookies.UserName, cookies.lastLoginAttempt]);

  useIdleTimer({
    timeout: MILLIS_30_MIN,
    onIdle: () => {
      setIsOpen(true);
      //user.logout();
    },
    debounce: 500,
  });

  const onRefresh = () => window.location.reload();

  const onLogout = () => window.location.href = LOGOUT_URL;

  return (
    <Dialog isOpen={isOpen} data-testid="idleNotificationDialog">
      <DialogTitle>Inactive Notification</DialogTitle>
      <DialogContent>
        <p className="tw-mt-2">
          You have been inactive for more than 30 minutes, please refresh the page to continue.
        </p>
      </DialogContent>

       <DialogFooter>
        
        {user.isLoggedIn ?<div className="d-flex justify-content-between mt-4">
            <Button as="secondary" className="tw-mt-0" onClick={onRefresh}>
              Refresh
            </Button>
            <Button as="primary" className="tw-mt-0" onClick={onLogout}>
              Logout now
            </Button>
          </div> : <div className="tw-flex tw-justify-end">
          <Button as="secondary" className="tw-mt-0" onClick={onRefresh}>Refresh</Button>
        </div> }
      </DialogFooter>
    </Dialog>
  );
}

