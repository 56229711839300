import { useAutoAnimate } from '@formkit/auto-animate/react';
import clsx from 'clsx';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, type UseFormReturn, useWatch } from 'react-hook-form';
import { TbReload } from 'react-icons/tb';

import NSWInputActionButton from '@components/Form/components/NSWInputActionButton';
import NSWInputField from '@components/Form/components/NSWInputField';
import NSWInputOutline from '@components/Form/components/NSWInputOutline';
import NSWInputWrapper from '@components/Form/components/NSWInputWrapper';
import SortedTable from '@components/Table/SortedTable';

import DebugConsole from '@helper/functions/console';

type Props = {
  searchAsync: (searchTerms) => Promise<TerminalProperties[]>;

  onOpen?: () => void;
  onClose?: () => void;

  onSelect?: (item) => void;
  onReset?: () => void;

  hookForm: UseFormReturn<any>;
  name: string;
  label?: string;

  containerClassName?: string;

  // Which property of the table should be selected and passed to React Hook Form?
  selectPropertyName: string;

  // Should the table collapse after selection?
  collapseOnSelect?: boolean;

  // Lock search box after selection? Is also synchronizes the search box value with the hookform value on selection.
  lockInputOnSelect?: boolean;
};

export const tableHeaders: {
  key: keyof TerminalProperties;
  name: string;
}[] = [
  // {
  //   key: 'healthEntity',
  //   name: 'Health Entity',
  // },
  {
    key: 'healthEntityCode',
    name: 'HE Code',
  },
  {
    key: 'terminalLocation',
    name: 'Location',
  },
  {
    key: 'terminalName',
    name: 'Terminal Name',
  },
  {
    key: 'terminalID',
    name: 'Terminal ID',
  },
];

export default function TerminalSelector({
  searchAsync,
  onSelect,
  onReset,
  onOpen,
  onClose,
  hookForm,
  name,
  label,
  containerClassName,
  selectPropertyName,
  collapseOnSelect = false,
  lockInputOnSelect = true,
}: Props) {
  const [autoAnimate] = useAutoAnimate();
  const [terminalList, setTerminalList] = useState<TerminalProperties[]>([]);
  const [showList, setShowList] = useState(true);

  const { setValue } = hookForm;

  const getData = useCallback(async () => {
    if (searchAsync) {
      try {
        const data = await searchAsync('');

        DebugConsole.debug(data);

        setTerminalList(data);
      } catch (err) {
        DebugConsole.error(err);
        return;
      }
    }
  }, [searchAsync]);

  const handleSearch = useCallback(
    async (searchTerms) => {
      if (searchAsync) {
        const results = await searchAsync(searchTerms);

        if (results) {
          setTerminalList(results);
        }

        setShowList(true);
      }
    },
    [searchAsync],
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const handleSelect = useCallback(
    (item: TerminalProperties) => {
      DebugConsole.debug(item);
      setValue(name, item[selectPropertyName]);

      if (collapseOnSelect) {
        setShowList(false);
      }

      if (onSelect) {
        onSelect(item);
      }
    },
    [collapseOnSelect, name, onSelect, selectPropertyName, setValue],
  );

  const handleReset = useCallback(() => {
    console.log('Reset field', name);

    setTerminalList([]);
    setValue(name, '');

    setShowList(false);

    if (onReset) {
      onReset();
    }
  }, [name, onReset, setValue]);

  return (
    <div ref={autoAnimate} className={containerClassName}>
      <div className="tw-mt-2 tw-max-w-screen-xs">
        <h4>{label || 'Search Terminal'}</h4>
        <SearchBox
          hookForm={hookForm}
          name={name}
          onSearch={handleSearch}
          onReset={handleReset}
          lockInputOnSelect={lockInputOnSelect}
        />
      </div>

      {showList && (
        <div className="tw-max-h-[75vh] tw-overflow-auto tw-my-4">
          <SortedTable
            title="All Sites"
            data={terminalList}
            headers={tableHeaders}
            isLoading={false}
            placeholder={'Sorry, we could not find any available records.'}
            cellAlignMiddle
            rowOnClick={handleSelect}
          />
        </div>
      )}
    </div>
  );
}

function SearchBox({
  hookForm,
  name,
  disabled = false,
  locked = false,
  lockInputOnSelect = true,
  containerClassNames = '',
  onSearch,
  onReset,
  rules = undefined,
  errorMessageHandler = undefined,
  errorClassName = '',
}) {
  const inputRef = useRef(null);

  const { control } = hookForm;

  const formValue = useWatch({ control, name });

  const [isFocused, setIsFocused] = useState(false);

  const handleSearch = useCallback(() => {
    if (formValue) {
      onReset();
      inputRef.current.value = '';
    } else {
      onSearch(inputRef.current.value);
    }
  }, [formValue, onReset, onSearch]);

  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleSearch();
      }
    },
    [handleSearch],
  );

  useEffect(() => {
    console.log(formValue);
    if (formValue) {
      inputRef.current.value = formValue;
    } else {
      inputRef.current.value = '';
    }
  }, [formValue]);

  const isLocked: boolean = locked || Boolean(lockInputOnSelect === true && formValue);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      disabled={disabled}
      render={({ field, fieldState: { invalid, error } }) => {
        return (
          <>
            <NSWInputWrapper
              isFocused={isFocused}
              disabled={field.disabled || isLocked}
              className={containerClassNames}
              error={invalid}
            >
              <NSWInputOutline>
                <NSWInputField
                  id={name}
                  ref={(r) => {
                    field.ref(r);
                    inputRef.current = r;
                  }}
                  disabled={field.disabled || isLocked}
                  autoComplete="one-time-code"
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => {
                    setIsFocused(false);
                    // field.onBlur();
                  }}
                  onKeyUp={handleKeyUp}
                  aria-invalid={invalid}
                />

                <NSWInputActionButton
                  onClick={handleSearch}
                  icon={isLocked ? <TbReload /> : undefined}
                />
              </NSWInputOutline>
            </NSWInputWrapper>
            {error && (
              <span
                className={clsx({
                  'nsw-form__helper nsw-form__helper--error': 1,
                  [errorClassName]: errorClassName,
                })}
                id={`${field.name}-error-text`}
              >
                <i className="material-icons nsw-material-icons" tabIndex={-1} aria-hidden="true">
                  cancel
                </i>
                {errorMessageHandler
                  ? errorMessageHandler({
                      target: {
                        value: field.value,
                        error: error.message,
                      },
                    })
                  : error.message ||
                    (field.value ? 'Field data is invalid' : 'This field is required')}
              </span>
            )}
          </>
        );
      }}
    />
  );
}
