import DebugConsole from '@helper/functions/console';

export default function Masthead(props) {
  DebugConsole.debug(process.env.REACT_APP_STAGE);
  return (
    <>
      <nav className="nsw-skipto" aria-label="Skip to links">
        <a className="nsw-skipto__link" href="#main-navigation">
          <span>Skip to navigation</span>
        </a>
        <a className="nsw-skipto__link" href="#page-content">
          <span>Skip to content</span>
        </a>
      </nav>
      <div className="nsw-masthead">
        <div className="nsw-container">
          <p className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between">
            <span>A NSW Government website</span>
          </p>
        </div>
      </div>
    </>
  );
}
