import clsx from 'clsx';
import { useMemo } from 'react';

interface InPageAlertProps {
  as?: 'info' | 'error' | 'warning' | 'success';
  title?: string;
  content?: string;
  contentElement?: React.ReactElement;
  [key: string]: any;
}

export default function InPageAlert({
  as = 'info',
  title = '',
  content = '',
  contentElement = <></>,
  className = '',
  ...others
}: InPageAlertProps) {
  const alertClassName = useMemo(() => {
    switch (as) {
      case 'info':
        return 'nsw-notification--info';
      case 'error':
        return 'nsw-notification--error';
      case 'warning':
        return 'nsw-notification--warning';
      case 'success':
        return 'nsw-notification--success';
      default:
        return 'nsw-notification--info';
    }
  }, [as]);

  const alertIconName = useMemo(() => {
    switch (as) {
      case 'info':
        return 'info';
      case 'error':
        return 'cancel';
      case 'warning':
        return 'error';
      case 'success':
        return 'check_circle';
      default:
        return 'info';
    }
  }, [as]);

  return (
    <div
      className={clsx({
        'nsw-notification': 1,
        [alertClassName]: 1,
        [className]: className,
      })}
    >
      <i
        className="material-icons nsw-material-icons nsw-notification__icon"
        tabIndex={-1}
        aria-hidden="true"
      >
        {alertIconName}
      </i>
      <div className="nsw-notification__content">
        {title && <h4 className="nsw-notification__title">{title}</h4>}
        {content && <p>{content}</p>}
        {contentElement}
      </div>
    </div>
  );
}
