import clsx from 'clsx';
import styled from '@emotion/styled';
import { memo, useEffect, useRef } from 'react';
import { useSnackbarStore } from '@store/notification';

const delay = 12 * 1000;

const SnackbarNotification = () => {
  const isOpen = useSnackbarStore((s) => s.isOpen);
  const message = useSnackbarStore((s) => s.message);
  const type = useSnackbarStore((s) => s.type);
  const handleClose = useSnackbarStore((s) => s.handleClose);
  const timeout = useRef(null);

  useEffect(() => {
    if (isOpen) timeout.current = setTimeout(handleClose, delay);

    return () => timeout.current && clearTimeout(timeout.current);
  }, [handleClose, isOpen]);

  return (
    <Snackbar
      className={clsx(`type-${type}`, { 'is-open': isOpen, 'is-close': !isOpen })}
      data-testid="snackbar"
    >
      <p>{message}</p>
    </Snackbar>
  );
};

const Snackbar = styled.div`
  position: fixed;
  z-index: 9999;
  right: 50%;
  bottom: 40px;
  width: fit-content;
  padding: 1rem 2.5rem;
  box-shadow: 10px 10px 41px 6px rgba(0, 0, 0, 0.2);
  border-radius: var(--nsw-border-radius);
  text-align: center;
  pointer-events: none;
  transform: translateX(50%) translateY(300%);

  &.is-open {
    animation: slideIn 210ms ease-in-out forwards;
  }
  &.is-close {
    animation: slideOut 210ms ease-in-out forwards;
  }

  &.type-success {
    background-color: var(--nsw-status-success-bg);
    color: var(--nsw-black);
  }
  &.type-error {
    background-color: var(--nsw-status-error);
    color: var(--nsw-white);
  }

  &.type-thanks {
    background-color: var(--nsw-status-success-bg) ;
    color: var(--nsw-black);
  }

  p {
    font-size: 0.85rem;
    font-weight: 600;
  }

  @keyframes slideIn {
    from {
      transform: translateX(50%) translateY(300%);
    }
    to {
      transform: translateX(50%) translateY(0%);
    }
  }

  @keyframes slideOut {
    from {
      transform: translateX(50%) translateY(0%);
    }
    to {
      transform: translateX(50%) translateY(300%);
    }
  }
`;

export default memo(SnackbarNotification);
