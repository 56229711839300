import { StrictMode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';

import AppRouter from '@helper/router';

const App = () => {


  return (
    <StrictMode>
        <HelmetProvider>
          <CookiesProvider>
            <AppRouter />
          </CookiesProvider>
        </HelmetProvider>
    </StrictMode>
  );
};

export default App;
