import styled from '@emotion/styled';
import clsx from 'clsx';

const Modal = ({ isOpen, children, ...others }) => {
  return (
    <ModalContainer
      className={clsx({
        'dialog--show': isOpen,
        'dialog--hide': !isOpen,
      })}
      {...others}
    >
      {isOpen ? children : null}
    </ModalContainer>
  );
};

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.575);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
  z-index: 100;

  &.dialog--show {
    z-index: 9999;
    visibility: visible;
    opacity: 1;
  }
  &.dialog--hide {
    z-index: -1;
    visibility: hidden;
    opacity: 0;
  }

  @media only screen and (max-width: ${(p) =>
      // @ts-ignore
      p.theme.screens.md}) {
    padding: 1rem;
  }
`;

export const ModalContentContainer = styled.div`
  padding: 3rem;
  background-color: #eee;
  border-radius: 8px;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;

  @media only screen and (max-width: ${(p) =>
      // @ts-ignore
      p.theme.screens.sm}) {
    padding: 1rem;
  }
`;

export default Modal;
