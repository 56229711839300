import { Outlet } from 'react-router-dom';

import Layout from '.';
import { useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';  // Add this import statement
import { LOGOUT_URL } from '@helper/constants';

export default function RouterDefaultLayout() {
  // const [cookies, setCookie, removeCookie] = useCookies();
  // const { pathname } = useLocation();
  // // Get the current path from the history object
  // useEffect(() => {
  //   console.log("Initial load")
  //   if((cookies.UserFullName || cookies.UserName)){
  //     if(cookies.InitialLoad ==='true'){
  //       removeCookie("InitialLoad")
  //     }else{
  //       window.location.href = `${LOGOUT_URL}`;
  //     }
  //   }
  // }, []);

  return (
    <Layout>
      <Outlet />
    </Layout>
  );
}
