import useNavStore from '@store/nav';

import Logo from './components/Logo';

export default function Header(props) {
  const navStore = useNavStore((s) => s);

  return (
    <header className="nsw-header">
      <div className="nsw-header__container">
        <div className="nsw-header__inner">
          <div className="nsw-header__main">
            <Logo />
          </div>
          <div className="nsw-header__menu tw-left-auto tw-right-[-0.375rem]">
            <button
              type="button"
              className="nsw-header-btn nsw-header-btn--menu js-open-navigation"
              aria-expanded="false"
              aria-controls="main-navigation"
              onClick={navStore.openNav}
            >
              <i
                className="material-icons nsw-material-icons nsw-header-btn__icon"
                tabIndex={-1}
                aria-hidden="true"
              >
                menu
              </i>
              <span className="nsw-header-btn__sub">
                <span className="sr-only">Open</span> Menu
              </span>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

export function HeaderMinimal(props) {
  return (
    <header className="nsw-header">
      <div className="nsw-header__container">
        <div className="nsw-header__inner">
          <div className="nsw-header__main">
            <Logo />
          </div>
        </div>
      </div>
    </header>
  );
}
