import type { EnvType } from './feature-flags';

// Current environment. Use default `prod` if not defined.
const ENV: EnvType = (process.env.REACT_APP_STAGE as EnvType | undefined) || 'prod';

// What environments allow printing to console?
const ALLOWED_ENV = ['dev', 'test', 'uat'];

type ConsoleFunction = (...params: any[]) => void;
type LogLevel = 'log' | 'info' | 'debug' | 'warn' | 'error' | 'dir';

// A wrapped console object to mute console prints in certain environments, such as Prod. If something needs to be always print, use the original console object and functions.
const DebugConsole: Record<LogLevel, ConsoleFunction> = {
  debug: (...params: any[]) => {},
  log: (...params: any[]) => {},
  info: (...params: any[]) => {},
  warn: (...params: any[]) => {},
  error: (...params: any[]) => {},
  dir: (...params: any[]) => {},
};

// Bind the custom console functions to the global console functions. It is needed to correctly display the line number in the console.
if (ALLOWED_ENV.includes(ENV)) {
  if (window) {
    Object.keys(DebugConsole).forEach(
      (level) => (DebugConsole[level] = window.console[level].bind(window.console[level])),
    );
  } else {
    Object.keys(DebugConsole).forEach(
      (level) => (DebugConsole[level] = console[level].bind(console[level])),
    );
  }
}

export default DebugConsole;
