import { create } from 'zustand';
import { usePaymentStore } from './generic-payment';

export const useDialogStore = create((set) => ({
  isPaymentDialogOpen: false,
  handlePaymentDialog: (state) => {
    set({ isPaymentDialogOpen: state });
    if (state === false) usePaymentStore.getState().resetPaymentStoreOnClose();
  },
}));
