import { useEffect, useState } from 'react';

import useAuthStore from '@src/store/auth';
import { useCashierExceptionStore } from '@src/store/cashier-exceptions';

import InPageAlert from '@components/InPageAlert';

export default function TerminalExceptionAlert() {
  const exceptionData = useCashierExceptionStore((s) => s.data);
  const getExceptions = useCashierExceptionStore((s) => s.getExceptions);
  const user = useAuthStore((s) => s.userAuthState);

  const [exceptionCount, setExceptionCount] = useState(0);

  useEffect(() => {
    if (!user) return;

    if (user.UserEmail) {
      getExceptions();
    }
  }, [getExceptions, user]);

  useEffect(() => {
    if (!user.UserFullName) return;

    if (!exceptionData) return setExceptionCount(0);

    const relatedExceptions = exceptionData.filter(
      (x) => x.cashierFullName.toLowerCase() === user.UserFullName.toLowerCase(),
    );

    setExceptionCount(relatedExceptions.length);
  }, [exceptionData, user.UserFullName]);

  return (
    <>
      {exceptionCount > 0 && (
        <InPageAlert
          as="warning"
          title="Unresolved Exceptions"
          content={
            exceptionCount > 1
              ? `There are ${exceptionCount} exceptions to be resolved. Please review them on the Error Correction page.`
              : `There is an exception to be resolved. Please review it on the Error Correction page.`
          }
        />
      )}
    </>
  );
}
