import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';

import Button from '@components/Button';
import InPageAlert from '@components/InPageAlert';
import { ComponentLoading } from '@components/Loading';
import { Dialog, DialogContent, DialogFooter } from '@components/NativeDialog';
import TerminalSelector from '@components/TerminalSelector';

import DebugConsole from '@helper/functions/console';
import useAuth from '@helper/hooks/useAuth';
import useQueryParameters from '@helper/hooks/useQueryParameters';
import { setUserDefaultTerminal } from '@helper/service/pos-terminal';

import { useCashierTerminalStore } from '@store/cashier-terminal';

import TerminalExceptionAlert from './TerminalExceptionAlert';

export default function TerminalSelectPrompt() {
  // Abort controller
  const abortControl = useRef(null);
  if (!abortControl.current) abortControl.current = new AbortController();
  useEffect(() => () => abortControl.current.abort(), []);

  // Get url search params
  const query = useQueryParameters();
  const selectTerminal = useMemo(() => query.get('selectTerminal'), [query]);

  // User auth
  const user = useAuth();

  // Cookies
  const [cookies, setCookie] = useCookies(['DefaultTerminalID']);

  // Auto animate
  const [autoAnimate] = useAutoAnimate();

  // React Hook Form
  const hookForm = useForm({
    defaultValues: {
      updateTerminal: false,
      terminalID: '',
    },
  });
  const { handleSubmit, watch, setValue } = hookForm;

  // Component states
  const [isOpen, setIsOpen] = useState(false);
  const [dismissed, setDismissed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [terminalInfo, setTerminalInfo] = useState(null);

  // Stores
  const searchTerminalList = useCashierTerminalStore((s) => s.searchTerminalList);
  const getTerminalList = useCashierTerminalStore((s) => s.getTerminalList);
  const getTerminalInfo = useCashierTerminalStore((s) => s.getTerminalInfo);

  // Whether the user has terminal access. Determined by cookie value.
  const [hasTerminalAccess, setHasTerminalAccess] = useState(false);
  useEffect(() => {
    if (user.isLoading) {
      return setHasTerminalAccess(false);
    }

    if (!user.PaymentMethod.includes('terminal')) {
      return setHasTerminalAccess(false);
    }

    // Check if the use has access to any terminal. If none, do not show the prompt.
    getTerminalList(user).then((allAllowedTerminals) => {
      if (abortControl.current.signal.aborted) return;

      if (!allAllowedTerminals || allAllowedTerminals.length === 0) {
        return setHasTerminalAccess(false);
      }

      setHasTerminalAccess(true);
    });
  }, [getTerminalList, user]);

  useEffect(() => {
    /**
     * Hide terminal if the dialog is already dismissed, or if no terminal access.
     */
    if (dismissed || !hasTerminalAccess) {
      setIsOpen(false);
      return;
    }

    // Open dialog if `selectTerminal` search param is set
    if (selectTerminal) {
      setIsOpen(true);
    }
  }, [selectTerminal, dismissed, hasTerminalAccess]);

  const handleClose = useCallback(() => {
    setDismissed(true);
    setIsOpen(false);
  }, []);

  const handleContinue = useCallback(
    (formData) => {
      DebugConsole.debug(formData);

      setIsLoading(true);

      if (!formData.updateTerminal) {
        handleClose();
        return;
      }

      setUserDefaultTerminal({
        username: user.UserName,
        terminalID: formData.terminalID,
      }).then((res) => {
        if (abortControl.current.signal.aborted) return;

        if (res) {
          setCookie('DefaultTerminalID', formData.terminalID);
          handleClose();
        } else {
          setIsLoading(false);
        }
      });
    },
    [handleClose, setCookie, user.UserName],
  );

  useEffect(() => {
    if (!selectTerminal || !cookies.DefaultTerminalID || !isOpen || !hasTerminalAccess) {
      setIsLoading(false);
      return setTerminalInfo(undefined);
    }

    setIsLoading(true);
    getTerminalInfo(user, user.defaultTerminalID)
      .then((terminal) => {
        // Do nothing if the component has unmounted before the above async function returns
        if (abortControl.current.signal.aborted) return;

        setIsLoading(false);

        if (!terminal) {
          return setTerminalInfo(false);
        }

        return setTerminalInfo(terminal);
      })
      .catch((err) => {
        setIsLoading(false);
        DebugConsole.error(err);
        setTerminalInfo(false);
      });
  }, [cookies.DefaultTerminalID, getTerminalInfo, hasTerminalAccess, isOpen, selectTerminal, user]);

  const handleSearchTerminal = useCallback(
    async (searchTerms) => {
      setIsLoading(true);
      let results = [];

      if (searchTerms === undefined || searchTerms === '') {
        results = await getTerminalList(user);
      } else {
        const res = await searchTerminalList(user, searchTerms);
        results = res;
      }

      DebugConsole.log(results);

      setIsLoading(false);
      return results;
    },
    [getTerminalList, searchTerminalList, user],
  );

  const handleExpandTerminalList = useCallback(() => {
    setValue('updateTerminal', true);
  }, [setValue]);

  // if `selectTerminal` query does not exist or is falsy, render nothing
  if (!selectTerminal) return null;

  // Only authenticated and cashier users should see this prompt
  if (user.isLoading || !user.isLoggedIn || !user.Roles.includes('cashier') || !hasTerminalAccess)
    return null;

  return (
    <Dialog isOpen={isOpen}>
      {/* <DialogTitle>Confirm POS Terminal</DialogTitle> */}
      <DialogContent>
        <div ref={autoAnimate}>
          {cookies.DefaultTerminalID ? (
            <>
              <p>
                Your current default Terminal ID is:{' '}
                <strong>
                  {cookies.DefaultTerminalID} {terminalInfo && `(${terminalInfo?.terminalName})`}
                </strong>
              </p>
              {terminalInfo === false && !watch('updateTerminal') && (
                <InPageAlert
                  as="warning"
                  title="Terminal not found"
                  content="We cannot find a terminal with the above ID. Please make sure your settings are up-to-date."
                />
              )}
            </>
          ) : (
            <p>You do not have a default terminal selected.</p>
          )}
          <TerminalExceptionAlert />
          {watch('updateTerminal') && (
            <TerminalSelector
              hookForm={hookForm}
              name="terminalID"
              selectPropertyName="terminalID"
              searchAsync={handleSearchTerminal}
              collapseOnSelect
              containerClassName="tw-mt-6"
            />
          )}
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="tw-flex tw-flex-col xs:tw-flex-row tw-flex-wrap tw-gap-4 tw-justify-between tw-items-center">
          <div>
            {!watch('updateTerminal') ? (
              <Button
                theme="dark-outline"
                className="tw-m-0 tw-w-full xs:tw-w-auto"
                onClick={handleExpandTerminalList}
              >
                Update Terminal
              </Button>
            ) : (
              <Button
                theme="dark-outline"
                className="tw-m-0 tw-w-full xs:tw-w-auto"
                onClick={handleClose}
              >
                Cancel and Close
              </Button>
            )}
          </div>
          <div className="tw-flex tw-items-center tw-gap-4">
            {isLoading && <ComponentLoading />}
            <Button
              className="tw-m-0 tw-w-full xs:tw-w-auto"
              onClick={handleSubmit(handleContinue)}
              disabled={
                user.isLoading || (watch('updateTerminal') && !watch('terminalID')) || isLoading
              }
            >
              {watch('updateTerminal') ? 'Update and Continue' : 'Continue'}
            </Button>
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
