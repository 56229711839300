import {
  fill as _fill,
  forEach as _forEach,
  forIn as _forIn,
  has as _has,
  zipObject as _zipObject,
} from 'lodash';

import { FeatureFlags } from '@src/features.config';

/**
 * These env types should match the `env` files
 */
export type EnvType = 'dev' | 'devoc' | 'test' | 'tstoc' |'uat' | 'uatoc' | 'stg' | 'prod';
export const EnvList: Array<EnvType> = ['dev', 'devoc', 'test', 'tstoc', 'uat', 'uatoc', 'stg', 'prod'];

/**
 * The env to be used if it is not explicitly provided
 */
export const defaultEnv: EnvType = 'prod';

export type EnvFlags = Record<EnvType, boolean>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultOff = _zipObject(EnvList, _fill(Array(EnvList.length), false)) as Record<
  EnvType,
  boolean
>;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const defaultOn = _zipObject(EnvList, _fill(Array(EnvList.length), true)) as Record<
  EnvType,
  boolean
>;

// To check if a given object is the type `EnvFlags`
function _isEnvFlags(val: any) {
  if (typeof val !== 'object') return false;

  const hasMissingFlag = EnvList.find((env) => {
    if (_has(val, env)) return false;
    return true;
  });

  return !hasMissingFlag;
}

// Recursively parse a collection of feature flags according to the current environment.
function _parseFeatures(val: any): Record<string, boolean | Record<string, boolean>> | boolean {
  if (typeof val !== 'object') {
    return val;
  }

  if (_isEnvFlags(val)) {
    const env = process.env.REACT_APP_STAGE || defaultEnv;
    return val[env] || false;
  }

  _forIn(val, (prop, key) => {
    const mapped = _parseFeatures(prop);
    val[key] = mapped;
  });

  return val;
}

export function mapFeatures(val: FeatureFlags<EnvFlags | boolean>) {
  return _parseFeatures(val) as FeatureFlags<boolean>;
}
//
// Helper function to enable a feature in some environments
export function enableEnvs(envList: Array<EnvType>) {
  const flags = { ...defaultOff };

  _forEach(envList, (env) => {
    flags[env] = true;
  });

  return flags;
}

// Helper function to disable a feature in some environments
export function disableEnvs(envList: Array<EnvType>) {
  const flags = { ...defaultOn };

  _forEach(envList, (env) => {
    flags[env] = false;
  });

  return flags;
}
