import styled from '@emotion/styled';

const StyledSectionLinksGroup = styled('div')(
  ({ theme }) => `
  @media only screen and (max-width: ${
    // @ts-ignore
    theme.screens.md
  }) {
    max-width: 100%;
  }
`
);

export default function EnableSectionLinks() {
  return (
    <>
      <StyledSectionLinksGroup className="section-links__group">
        <h3 className="section-links__heading">
          <span>More information</span>
        </h3>
        <ul className="section-links__list">
          <li className="section-links__item">
            <a
              href="https://www.enable.health.nsw.gov.au/about/contact"
              rel="noreferrer"
              target="_blank"
            >
              Contact EnableNSW
            </a>
          </li>
          <li className="section-links__item">
            <a href="https://www.enable.health.nsw.gov.au/" rel="noreferrer" target="_blank">
              Learn more about EnableNSW{' '}
            </a>
          </li>
          <li className="section-links__item">
            <a
              href="https://www.enable.health.nsw.gov.au/about/contact"
              rel="noreferrer"
              target="_blank"
            >
              Need assistance with your payment?
            </a>
            <span>
              {' '}
              Call 1 800 ENABLE (1 800 362 253) and then press 7, or, visit this link for more
              options.
            </span>
          </li>
        </ul>
      </StyledSectionLinksGroup>
      <div className="section-links__group d-none">
        <h3 className="section-links__heading">
          <span>Learn about us</span>
        </h3>
        <ul className="section-links__list">
          <li className="section-links__item">
            <a href="#demo">Demo link 1</a>
          </li>
          <li className="section-links__item">
            <a href="#demo">Demo link 2</a>
          </li>
        </ul>
      </div>
    </>
  );
}
