import clsx from 'clsx';
import { forwardRef } from 'react';

type Props = {
  class?: string;
  disabled?: boolean;
  error?: boolean;
  isFocused?: boolean;
  [key: string]: any;
};

const NSWInputWrapper = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const { className = '', disabled, error, isFocused, ...others } = props;

  return (
    <div
      ref={forwardedRef}
      className={clsx({
        'tw-group tw-mt-[0.25rem]': 1,
        'group-[.dynamic-fields]:tw-mt-auto': 1,
        [className]: className,
        focus: isFocused,
        error: error,
        disabled: disabled,
      })}
      {...others}
    ></div>
  );
});

export default NSWInputWrapper;
