import { Button } from 'nsw-ds-react';

import { Dialog, DialogContent, DialogFooter, DialogTitle } from '@components/NativeDialog';

import {LOGOUT_URL} from '@helper/constants'

export default function LogOutNotificationDialog({ isOpen, onClose, text }) {
  const closeDialog = () => {
    onClose(false)
  };

  const logout = () => {
    onClose(false)
    window.location.href=LOGOUT_URL;
  }

  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle>Session about to expire</DialogTitle>
      <DialogContent>
        <p className="tw-mt-2">{ text }</p>
      </DialogContent>

      <DialogFooter>
        <div className="d-flex justify-content-between mt-4">
          <Button as="secondary" className="tw-mt-0" onClick={closeDialog}>
            Close and continue
          </Button>
          <Button as="primary" className="tw-mt-0" onClick={logout}>
            Logout now 
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
}
