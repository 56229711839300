import format from 'date-fns/format';
import parse from 'date-fns/parse';
import { validate as uuidValidate, v5 as uuidv5 } from 'uuid';

export const usdCurrencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
}).format;

export function downloadCSV(filename, base64) {
  const fileArrayBuffer = base64ToArrayBuffer(base64);

  const blob = new Blob([fileArrayBuffer], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const downloadBtn = document.createElement('a');
  downloadBtn.href = url;
  downloadBtn.download = `${filename}.csv`;
  downloadBtn.click();
}

export function downloadPDF(filename, base64) {
  const fileArrayBuffer = base64ToArrayBuffer(base64);

  const blob = new Blob([fileArrayBuffer], { type: 'application/pdf' });
  const url = URL.createObjectURL(blob);

  const downloadBtn = document.createElement('a');
  downloadBtn.href = url;
  downloadBtn.download = filename;
  downloadBtn.click();
}

function base64ToArrayBuffer(base64String) {
  const binaryString = window.atob(base64String.replace(/\s/g, ''));
  const stringLength = binaryString.length;
  const bytes = new Uint8Array(stringLength);
  for (let i = 0; i < stringLength; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  return bytes.buffer;
}

export function formatJsonForPostPaymentDetails({
  activeInvoice,
  paymentData,
  authorizedAmount,
  portalPayment,
  amountDueRemaining,
}) {
  return {
    amount: authorizedAmount,
    portalPayment,
    customerAccount: activeInvoice.customerAccount,
    invoiceDate: activeInvoice.invoiceDate,
    invoiceNumber: activeInvoice.invoiceNumber,
    amountDueRemaining,
    healthEntity: activeInvoice.healthEntity,
    amountDueDate: activeInvoice.amountDueDate,
    healthEntityCode: activeInvoice.healthEntityCode,
    invoiceId: activeInvoice.invoiceId,
    transactionId: paymentData.processorInformation.transactionId,
    paymentRef: paymentData.id,
    retrievalReferenceNumber: paymentData.processorInformation.retrievalReferenceNumber,
  };
}

export function generateInvoiceNumber(length = 6) {
  return Math.random()
    .toString()
    .slice(2, 2 + length);
}

export function generatePseudoSequenceNumber() {
  const MILLISEC_IN_SEC = 1000;
  const EPOCH_SEC_2020_JAN_1 = 1577836800;

  return (
    '' +
    (Math.floor(Date.now() / MILLISEC_IN_SEC) - EPOCH_SEC_2020_JAN_1) +
    generateInvoiceNumber(2)
  );
}

export function generateMrn(prefix) {
  if (!prefix) prefix = '';

  const random = Math.floor(Math.random() * 100)
    .toString()
    .padStart(2, '0');

  const timeHex = Date.now().toString(16).toUpperCase();

  const id = prefix + random + timeHex;
  return id;
}

export function parseAndFormatDate(date) {
  const parsedDate = parse(date, 'dd-MM-yyyy', new Date());
  return format(new Date(parsedDate), 'dd-MMM-yyyy');
}

const nullValues = [undefined, null];

// A case-insensitive function to check whether either string is a substring of the other. Intended for simple string searching in a predictive text field.
export function matchStrings(a, b) {
  if (nullValues.includes(a) || nullValues.includes(b)) {
    return false;
  }

  a = a.toString().trim().toLowerCase();
  b = b.toString().trim().toLowerCase();

  return a.includes(b) || b.includes(a);
}

export function containsTerm(list = [], term = '') {
  return list.find((x) => matchStrings(x, term)) ? true : false;
}

/**
 * Use with `Array.sort()` or `Array.toSorted()` to sort objects
 * @param {String} key The attribute name to compare
 * @param {Boolean} [desc=false] To sort in descending order
 * @example Array.sort(compareBy('someAttrName'))
 */
export function compareBy(key, desc = false) {
  /**
   * Compare objects in ascending order.
   * @param {object} a
   * @param {object} b
   */
  function ascCompareFn(a, b) {
    return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
  }

  /**
   * Compare objects in descending order.
   * @param {object} a
   * @param {object} b
   */
  function descCompareFn(a, b) {
    return -ascCompareFn(a, b);
  }

  return desc ? descCompareFn : ascCompareFn;
}

export function isUUID(uuid: string): boolean {
  return uuidValidate(uuid);
}

// Just a constant UUID namespace to ensure generated UUIDv5 results are consistent. It serves no cryptographic value.
const hashNamespace = 'dedddc58-3b30-4266-889a-a1497b0363ae';

// Given a string, return a UUIDv5. It is intended to convert runtime string values (with unknown length and cleanliness) into a value suitable for the ReactElement `key` prop.
export function stringUuid(value: string): string {
  const hash = uuidv5(value, hashNamespace);
  return hash;
}
