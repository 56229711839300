import { useSnackbarStore } from "@store/notification";

export const handleErrorMessage = (error) => {
  const errorMessage = error?.response?.data.error ?? error.message;

  useSnackbarStore.getState().handleError(errorMessage);
  return false;
};

export const handleErrMessage = (message) => {
  useSnackbarStore.getState().handleError(message);
  return false;
};

export const handleSuccess = (message) => {
  useSnackbarStore.getState().handleSuccess(message);
  return false;
};