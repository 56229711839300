import clsx from 'clsx';

export default function GlobalAlert({ title, message, alertType , onClose, outageNotification }) {
  if (alertType === 'light' && outageNotification)
    {
      title = "Upcoming Maintenance - "+ title;
    }
    if (alertType === 'dark' && outageNotification)
      {
        title = title + " is currently unavailable"
      }
    
  return (
    <div
      className={clsx({
        'nsw-sitewide-message js-sitewide-message': 1,
        'nsw-sitewide-message--light': alertType === 'light',
        'nsw-sitewide-message--critical': alertType === 'critical',
        'nsw-sitewide-message--dark': alertType === 'dark',
      })}
      role="alert"
    >
      <div className="nsw-sitewide-message__wrapper">
        <div className="nsw-sitewide-message__content">
          <h2 className="nsw-sitewide-message__title">{title}</h2>
          {message && <p>{message}</p>}
        </div>
        <button type="button" className="nsw-sitewide-message__close" onClick={onClose}>
          <i className="material-icons nsw-material-icons" tabIndex={-1} aria-hidden="true">
            close
          </i>
          <span className="sr-only">Close message</span>
        </button>
      </div>
    </div>
  );
}
