import { create } from 'zustand';

import DebugConsole from '@helper/functions/console';
import { getCurrentOutageData } from '@helper/service/outage';

const CACHE_TTL = 10 * 60 * 1000; // 10 minutes, in ms

interface OutageStore {
  isInitializing: boolean;
  error: string;
  currentOutage: OutageRecord | undefined;
  outageRecord: OutageRecord | undefined;
  lastUpdated: number;
  isAlertOpen: boolean;

  initializeOutage: () => Promise<OutageRecord>;
  awaitAuthInit: () => Promise<boolean>;
  getOutageRecord : (callback: Function) => Promise<() => Promise<OutageRecord>>


  setAlertOpen: () => void;
  setAlertClosed: () => void;
  setOutageData: (outageRecord: OutageRecord) => void;
  updateOutageStatus: () => void;
}

export const useOutageStore = create<OutageStore>()((set, get) => ({
  outageRecord: undefined,
  isInitializing: true,
  error: '',
  currentOutage: {
    uuid: '',
    expectedStartDateTime: '',
    expectedEndDateTime: '',
    outageType: '',
    outageTitle: '',
    outageDetails: '',
    isDowntimeRequired: false,
    outageStatus: ''
  },
  // It is set as the current time because it is assumed to be set by the server data on initial page load.
  lastUpdated: Date.now(),

  isAlertOpen: false,

  initializeOutage: async () => {
    const currentOutage = await getCurrentOutageData();
    if (!currentOutage)
      {
        set({
          isInitializing: false,
          error: 'cannot fetch current outage data'
        });
        return null;
      }
      set ({isInitializing: false, currentOutage, error: ''});
      return currentOutage;
  },

  awaitAuthInit: async () => {
    while (!get().error && get().isInitializing) {
      await new Promise ((r) => setTimeout(r, 50));
    }
    if (get().error) return false;
    return true;
  },

  getOutageRecord: async (callback) => {
    try {
      const newOutage = await get().initializeOutage;
      if (callback) callback (newOutage);
      return newOutage;
    }
    catch (err){
      console.log(err);
      if (callback) callback(undefined);
      return undefined;
    }

  },

  setAlertOpen: () => {
    set({
      isAlertOpen: true,
    });
  },

  setAlertClosed: () => {
    set({
      isAlertOpen: false,
    });
  },

  setOutageData: (data) => {
    if (!data) return;

    set({
      outageRecord: data,
      lastUpdated: Date.now(),
    });
  },

  // Refresh outage status
  updateOutageStatus: () => {
    if (Date.now() - get().lastUpdated < CACHE_TTL) return;

    getCurrentOutageData()
      .then((data) => {
        DebugConsole.debug('Fetched outage data');
        DebugConsole.debug(data);

        // set({
        //   isAlertOpen: true,
        // })
      })
      .catch((err) => {
        DebugConsole.error(err);
      });
  },
}));
