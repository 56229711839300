import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import IdleNotificationDialog from '@components/IdleNotificationDialog';
import LogOutNotificationDialog from '@components/LogOutNotificationDialog';
import NoRoleDialog from '@components/NoRoleDialog';
import SnackbarNotification from '@components/SnackbarNotification';

import useAuth from '@helper/hooks/useAuth';

import emotionTheme from '@theme/emotion.theme';
import GlobalStyles from '@theme/global.styles';

export default function MinimalLayout(props) {
  const { children } = props;

  const user = useAuth();

  const [autoAnimateParent] = useAutoAnimate();

  return (
    <EmotionThemeProvider theme={emotionTheme}>
      <div ref={autoAnimateParent} id="body-wrapper">
        <GlobalStyles />

        <div id="page-content">{children}</div>

        <SnackbarNotification />
        <IdleNotificationDialog />
        <NoRoleDialog isOpen={user.openNoRoleDialog} onClose={user.updateOpenNoRoleDialog} />
        <LogOutNotificationDialog
          isOpen={user.openLogoutDialog}
          onClose={user.updateOpenLogoutDialog}
          text={user.textDialog}
        />
      </div>
    </EmotionThemeProvider>
  );
}
