import { intersection as _intersection } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import useAuth from '@helper/hooks/useAuth';

export default function PageRequireAuth({ roles = [], redirectURL = '/not-authorised', ...props }) {
  const [isAuthenticated, setIsAuthenticated] = useState(undefined);

  const user = useAuth();

  useEffect(() => {
    if (user.isLoading) return;

    if (user.isLoggedIn) {
      if (!roles || roles.length < 1) {
        setIsAuthenticated(true);
        return;
      }

      const hasRoles = _intersection(user.Roles, roles);

      if (hasRoles && hasRoles.length > 0) {
        setIsAuthenticated(true);
        return;
      }
    }

    setIsAuthenticated(false);
  }, [roles, user.Roles, user.isLoading, user.isLoggedIn]);

  if (isAuthenticated === undefined || isAuthenticated === null) {
    return <></>;
  }

  if (isAuthenticated) {
    return props.children;
  } else {
    return (
      <Navigate
        to={{
          pathname: redirectURL,
        }}
        replace={true}
      />
    );
  }
}
