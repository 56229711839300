import clsx from 'clsx';
import { forwardRef } from 'react';

type Props = {
  className?: string;
  [key: string]: any;
};

const NSWInputOutline = forwardRef<HTMLDivElement, Props>((props, forwardedRef) => {
  const { className, ...others } = props;

  return (
    <div
      ref={forwardedRef}
      className={clsx({
        'nsw-form__input custom-form-input-container tw-flex tw-items-center tw-h-full tw-py-0 tw-px-0': 1,
        'group-hover:tw-bg-[var(--nsw-text-hover)]': 1,
        'group-[.focus]:nsw-input-outline': 1,
        'group-[.error]:tw-border-2 group-[.error]:tw-border-[var(--nsw-status-error)]': 1,
        'group-[.error]:hover:tw-bg-[var(--nsw-status-error-bg)]': 1,
        'group-[.disabled]:tw-bg-[var(--nsw-palette-off-white)]': 1,
        'group-[.disabled]:tw-border-grey3': 1,
        [className]: className,
      })}
      {...others}
    ></div>
  );
});

export default NSWInputOutline;
