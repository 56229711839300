export function scrollToTop(options = { smooth: false }) {
  if (!document) return;

  document.querySelector('body')?.scrollIntoView({
    block: 'start',
    inline: 'nearest',
    behavior: options.smooth ? 'smooth' : 'instant',
  });
}

export function scrollToBody(options = { smooth: false }) {
  if (!document) return;

  document.querySelector('#page-content')?.scrollIntoView({
    block: 'start',
    inline: 'nearest',
    behavior: options.smooth ? 'smooth' : 'instant',
  });
}

export function scrollToElement(selectorQuery, options = { smooth: false }) {
  if (!document) return;

  document.querySelector(selectorQuery)?.scrollIntoView({
    block: 'start',
    inline: 'nearest',
    behavior: options.smooth ? 'smooth' : 'instant',
  });
}
