import clsx from 'clsx';
import { useEffect, useMemo } from 'react';
import { BiUserCircle } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import useAuthStore from '@src/store/auth';
import { useCashierExceptionStore } from '@src/store/cashier-exceptions';

import { LOGOUT_URL } from '@helper/constants';
import useNavLinks from '@helper/hooks/useNavLinks';

import useNavStore from '@store/nav';

export default function MainNav(props) {
  const navStore = useNavStore((s) => s);
  const navLinks = useNavLinks();

  const user = useAuthStore((s) => s.userAuthState);

  const getExceptions = useCashierExceptionStore((s) => s.getExceptions);
  const exceptionData = useCashierExceptionStore((s) => s.data);

  const userIsCashier = useMemo(() => {
    if (!user || user.isLoading) return false;

    if (user.Roles && user.Roles.includes('cashier')) {
      return true;
    }

    return false;
  }, [user]);

  useEffect(() => {
    if (userIsCashier) {
      getExceptions();
    }
  }, [getExceptions, user?.UserEmail, userIsCashier]);

  return (
    <nav
      id="main-navigation"
      className={clsx({
        'nsw-navigation': 1,
        'is-open': navStore.isNavigationOpen,
      })}
      aria-label="Main menu"
    >
      <div className="nsw-navigation__header">
        <h2 id="nsw-navigation">Menu</h2>
        <button
          type="button"
          className="nsw-navigation__close js-close-navigation"
          aria-expanded="true"
          onClick={navStore.closeNav}
        >
          <i className="material-icons nsw-material-icons" tabIndex={-1} aria-hidden="true">
            close
          </i>
          <span className="sr-only">Close Menu</span>
        </button>
      </div>
      <ul className="nsw-navigation__list">
        {navLinks.map((item) => (
          <li className="nsw-navigation__list-item" key={item.name}>
            {item.link.startsWith('/') ? (
              <Link to={item.link} className="nsw-navigation__link" onClick={navStore.closeNav}>
                <span className="nsw-navigation__link-text">{item.name}</span>
              </Link>
            ) : (
              <a href={item.link} rel="noreferrer" className="nsw-navigation__link" target="_blank">
                <span className="nsw-navigation__link-text">{item.name}</span>
              </a>
            )}
          </li>
        ))}
        {user?.isLoggedIn && (
          <>
            <li className="ml-auto">
              <Link
                to="/user-settings"
                className="nsw-navigation__link tw-border-0 tw-text-[100%] tw-leading-[var(--nsw-line-height)]"
                onClick={navStore.closeNav}
              >
                <span className="nsw-navigation__link-text tw-flex tw-items-center">
                  <BiUserCircle size={24} /> <span className="ml-2">{user?.UserFullName}</span>
                </span>
              </Link>
            </li>
            {userIsCashier && (
              <li>
                <Link
                  to="/cashier/cashier-report"
                  className="nsw-navigation__link tw-border-0 tw-text-[100%] tw-leading-[var(--nsw-line-height)]"
                  onClick={navStore.closeNav}
                >
                  <span className="nsw-navigation__link-text tw-flex tw-items-center">
                    Cashier Report
                  </span>
                </Link>
              </li>
            )}
            {exceptionData?.length > 0 && (
              <li>
                <Link
                  to="/cashier/error-correction"
                  className="nsw-navigation__link tw-border-0 tw-text-[100%] tw-leading-[var(--nsw-line-height)]"
                  onClick={navStore.closeNav}
                >
                  <span className="nsw-navigation__link-text tw-flex tw-items-center">
                    Error Correction
                  </span>
                </Link>
              </li>
            )}
            <li>
              <a href={LOGOUT_URL} rel="noreferrer" className="nsw-navigation__link">
                <span className="nsw-navigation__link-text">Logout</span>
              </a>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
}
