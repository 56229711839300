import { Global, css } from '@emotion/react';
// From design systems
import 'nsw-design-system/dist/css/main.css';

import { alpha } from '@helper/functions/colors';

import theme from '@theme/emotion.theme';

import { screens } from './constants';
// Preflight rules to override browser defaults
import './preflight.css';
import './tailwind.css';
// CSS tools
import './utilities.scss';

const selectionColor = alpha(theme.colors.primary, 0.8);

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        * {
          margin: 0;
          padding: 0;
          box-sizing: border-box;
        }

        :root {
          --nsw-font-family: 'Public Sans', Arial, sans-serif;
        }

        body {
          font-family: ${theme.fonts.primary};
        }

        #root {
          height: 100%;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
        }

        #body-wrapper {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
        }

        .full-width {
          width: 100% !important;
        }

        .full-height {
          height: 100% !important;
        }

        .text-center {
          text-align: center;
        }

        .text-left {
          text-align: left;
        }

        .flex-1 {
          flex: 1;
        }

        .flex-2 {
          flex: 2;
        }

        .custom-scroller {
          overflow: auto;

          &::-webkit-scrollbar {
            width: 0.36rem;
            height: 0.36rem;
          }

          &::-webkit-scrollbar-track {
            background-color: transparent;
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.25);
            border-radius: 25px;
          }
        }

        .nsw-button {
          font-size: 0.875rem !important;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        ::selection {
          background-color: ${selectionColor};
          color: rgba(255, 255, 255, 0.95);
        }

        .page-container {
          max-width: 1200px;
          width: 100vw;
          margin: 0 auto;
        }

        .fade-in {
          animation: fadeIn 500ms ease-in-out forwards;
        }

        .nsw-form__input:disabled,
        .nsw-form__select:disabled {
          -webkit-text-fill-color: var(--nsw-text-dark) !important;
          -webkit-opacity: 1;
          color: var(--nsw-text-dark) !important;
        }

        .custom-form-input-container:has(input:-internal-autofill-selected) {
          background-color: rgb(232, 240, 254) !important;
        }

        .cybersource-form-input {
          font-family: Montserrat, sans-serif !important;
          font-size: 14px;
          background-color: transparent;
        }

        .nsw-form-font {
          font-family: var(--nsw-font-family);
          font-size: var(--nsw-font-size-sm-mobile);
          line-height: var(--nsw-line-height-sm-mobile);
        }

        .tw-grid > div.nsw-form__group {
          margin-top: 0;
        }

        .tw-flex > div.nsw-form__group {
          margin-top: 0;
        }

        // Override MUI input on-focus outline
        .Mui-focused fieldset {
          border-width: 1px !important;
          border-color: black !important;
        }

        @media (min-width: 62em) {
          .nsw-form-font {
            font-size: var(--nsw-font-size-sm-desktop);
            line-height: var(--nsw-line-height-sm-desktop);
          }
        }

        p.error-message {
          font-size: 0.75rem;
          color: var(--nsw-status-error);
          margin-top: 0.2rem;
          margin-bottom: 0.65rem;
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
          }

          to {
            opacity: 1;
          }
        }

        @media only screen and (max-width: ${screens.sm}) {
          .nsw-button {
            font-size: 0.7rem !important;
            padding: 0.45rem 1.2rem !important;
          }

          .nsw-form-select {
            font-size: 0.9rem !important;
            padding-top: 0.65rem !important;
            padding-bottom: 0.65rem !important;
          }

          .nsw-form-radio {
            .nsw-form-radio__label {
              font-size: 0.8rem;
            }
          }
        }
      `}
    />
  );
}
